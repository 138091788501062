<template>
<div>
  <b-overlay :show="showLoading" no-wrap />
  <b-row>
    <b-col cols="12" class="mb-2">
      <h2 class="mb-0 text-uppercase font-weight-bolder">
          étape 4 : ajout des pièces justificatives
      </h2>
      <h6>
          Ajoutez l'ensemble des pièces justificatives listées 
      </h6>
    </b-col>
  </b-row> 


  <b-alert
    v-if="documentErrors.length>0"
    variant="danger"
    show
    class="mb-0"
  >
    <div class="alert-body">
      <div v-for="(doc,key) in documentErrors" :key="key">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
      {{doc}}
      </div>
    </div>
  </b-alert>
  <b-alert
    v-if="apiNotAvailable"
    variant="danger"
    show
    class="mb-0"
  >
    <div class="alert-body">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
        Service de contrôle du gel des avoirs temporairement indisponible.
    </div>
  </b-alert>

  <b-alert
    v-if="errorsWsSouscription.length>0"
    variant="danger"
    show
    class="mb-0"
  >
    <div class="alert-body">
      <div v-for="(err,key) in errorsWsSouscription" :key="key">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
      {{err}}
      </div>
    </div>
  </b-alert>

  <!-- <b-form-invalid-feedback :state="!errorsWsSouscription.length>0">
    <div v-for="(err,key) in errorsWsSouscription" :key="key">
      <span class="d-block">{{err}}</span>
    </div>
  </b-form-invalid-feedback> -->
  <b-modal
      id="modal-lg"
      v-if="isActive"
      hide-footer
      centered
      size="lg"
      title="Documents"
    >
      <b-card-body>
        <b-row class="mb-2">
          <!-- <b-col md="4">
            <h6>Type De Document</h6>
          </b-col> -->
          <b-col md="10">
            <h6>Nom De Document</h6>
          </b-col>
          <b-col md="2" class="text-center">
            <h6>Actions</h6>
          </b-col>
        </b-row>
        <b-row class="mb-2" v-for="(doc,index) in documentsFiltredModal" :key="index"> 
          <!-- <b-col md="4">
            <span>{{doc.document.document_type.type}}</span>
          </b-col> -->
          <b-col md="10" class="text-truncate">
            <span >{{doc.document.nom_custom}}</span>
          </b-col>
          <b-col md="2" class="text-center">
              <feather-icon
                @click="affecterDocument(doc)"
                icon="CheckCircleIcon"
                size="24"
                class="mr-50 custom-icon-box"
              />
          </b-col>
        </b-row>
      </b-card-body>
  </b-modal>
  <b-modal
    id="modal-lg-2"
    v-if="isActive"
    hide-footer
    centered
    size="lg"
    title="Documents"
  >
    <b-card-body>
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- <b-form-group
          label="Type de Document"
          label-for="type_document"
        >
          <b-form-select
          v-model="$v.document.type_doc.$model"
          id="type_document"
          >
            <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
            <b-form-select-option :value="Tdoc" v-for="Tdoc in document_type" :key="Tdoc.id">{{ Tdoc.type }}</b-form-select-option>
          </b-form-select>
        </b-form-group> -->

        <b-form-group
          label="Nom du document"
          label-for="nom_document"
        >
          <b-form-input
            id="nom_document"
            v-model="$v.document.nom_document.$model"
            trim
            placeholder="Nom du document"
      
          />
        </b-form-group>

        <b-row>
          <b-col md="12">
            <div class="demo-inline-spacing">
              <input type="file" hidden="hidden" @change="onFileChange"
                name="file" data-toggle="custom-file-input" ref="file"
                id="file" aria-describedby="button-addon-doc"/>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="outline-primary"
                @click="$refs.file.click()"
              >
                Importer un document 
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="primary"
                @click="add"
              >
                Enregistrer 
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-modal>

  <div class="my-4">
    <b-row class="mb-2" v-if="devis.mode_paiement=='PRELEVEMENT'">
      <!-- <b-col xl="4" sm="12">
        <span>Document Souscripteur</span>
      </b-col> -->
      <b-col xl="4" sm="12">
        <span>RIB de prélèvement des cotisations *</span>
      </b-col>
      <!-- <b-col xl="4" sm="12">
        <span>{{ $_.get(documentsDevisRib,'document.nom_custom','') }}</span>
      </b-col> -->
      <b-col xl="4" sm="12">
          <span v-if="renderComponent">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none pt-0"
              no-caret
            >
              <template v-slot:button-content>
                <span
                  v-if="!documentsDevisRib"
                  block
                  variant="outline-primary"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="12"
                  />
                  Ajouter un fichier
                </span>
                <span
                  v-else
                  block
                  variant="outline-primary"
                  class="text-capitalize"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="12"
                  />
                  visualiser / 
                  <feather-icon
                    icon="XIcon"
                    size="12"
                  />
                  détacher
                </span>
              </template>
              <b-dropdown-item  v-if="documentsDevisRib"
                                @click="removeAffecter(documentsDevisRib.id)">
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span>Détacher</span>
              </b-dropdown-item>
              <div v-if="!documentsDevisRib" @click="$refs.fileRib.click()" class="btn-dropdown-custom">
                <feather-icon
                  icon="UploadIcon"
                  class="mr-50"
                />
                <input type="file" hidden="hidden" @change="onFileChangeRib('RIB')"
                  name="file" data-toggle="custom-file-input" ref="fileRib"
                  id="file" aria-describedby="button-addon-doc"/>
                <span>
                  Parcourir cet ordinateur 
                </span>
              </div>

              <b-dropdown-item  v-if="!documentsDevisRib"
                                v-b-modal.modal-lg
                                @click="openModal('RIB')">
                <feather-icon
                  icon="LinkIcon"
                  class="mr-50"
                />
                <span>Parcourir la GED</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="documentsDevisRib"
                               @click="ShowFile(documentsDevisRib)">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Visualiser</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
      </b-col>
    </b-row>
    <b-row v-if="paiement.remboursementTitulaireCompte || devis.mode_paiement=='CHEQUE' ">
      <!-- <b-col xl="4" sm="12">
        <span>Document Souscripteur</span>
      </b-col> -->
      <b-col xl="4" sm="12">
        <span>RIB de remboursement des prestations * </span>
      </b-col>
      <!-- <b-col xl="4" sm="12">
        <span>{{$_.get(documentsDevisRembRib,'document.nom_custom','')}}</span>
      </b-col> -->
      <b-col xl="4" sm="12">
        <span v-if="renderComponent">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none pt-0"
            no-caret
          >
            <template v-slot:button-content>
              <span
                v-if="!documentsDevisRembRib"
                block
                variant="outline-primary"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="12"
                />
                Ajouter un fichier
              </span>
              <span
                v-else
                block
                variant="outline-primary"
                class="text-capitalize"
              >
                <feather-icon
                  icon="EyeIcon"
                  size="12"
                />
                visualiser / 
                <feather-icon
                  icon="XIcon"
                  size="12"
                />
                détacher
              </span>

            </template>
            <b-dropdown-item  v-if="documentsDevisRembRib"
                              @click="removeAffecter(documentsDevisRembRib.id)">
              <feather-icon
                icon="XIcon"
                class="mr-50"
              />
              <span>Détacher</span>
            </b-dropdown-item>
            <div v-if="!documentsDevisRembRib" @click="$refs.file.click()" class="btn-dropdown-custom">
              <feather-icon
                icon="UploadIcon"
                class="mr-50"
              />

                <input type="file" hidden="hidden" @change="onFileChange('REMBOURSEMENT RIB')" 
                  name="file" data-toggle="custom-file-input" ref="file"
                  id="file" aria-describedby="button-addon-doc"/>
                <span>
                  Parcourir cet ordinateur 
                </span>
            </div>
            <!-- RIB de prélèvement -->
            <b-dropdown-item  v-if="!documentsDevisRembRib"
                              v-b-modal.modal-lg
                              @click="openModal('REMBOURSEMENT RIB')"> 
              <feather-icon
                icon="LinkIcon"
                class="mr-50"
              />
              <span>Parcourir la GED</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="documentsDevisRembRib"
                              @click="ShowFile(documentsDevisRembRib)">
              <feather-icon
                icon="EyeIcon"
                class="mr-50"
              />
              <span>Visualiser</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
        
      </b-col>
    </b-row>
  </div>


  <b-row>
    <b-col lg="12" class="mt-4">
      <b-button
        variant="primary"
        class="float-left"
        size="lg"
        @click="backStep"
      >
        Précédent
      </b-button>
      <b-button
        variant="primary"
        class="float-right"
        size="lg"
        @click="submit"
        v-if="!suspected"
        :disabled = "disabled_btn"

      >
        Suivant
      </b-button>
    </b-col>
  </b-row>
  <b-modal id="modal-display-document" ref="modal-display-document"
            :size="documentFile.extensionDocument === 'PDF' ? 'lg' : 'sm'" :title="documentFile.name"
            cancel-variant="outline-secondary" cancel-title="Fermer"
            ok-title="Télécharger"
            @ok="downloadDocument(documentFile.base64, documentFile.nameToDownload)"
  >
    <form ref="form" :style="{height : documentFile.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden'}">
      <b-img v-if="documentFile.extensionDocument === 'IMAGE'" :alt="documentFile.name" :src="documentFile.base64" fluid/>
      <iframe v-else-if="documentFile.extensionDocument === 'PDF'" :src="documentFile.base64" height="100%" width="100%" :style="hideToolbar()"/>
    </form>
  </b-modal>



</div>
</template>

<script>
import documentService from '@/shared/services/documentService'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
    BOverlay,BSidebar,BAvatar, VBToggle, BCardText,BCard, BCardHeader, BCardTitle, BCardBody, BFormCheckbox, BForm, 
    BInputGroup,BFormInvalidFeedback, BInputGroupPrepend ,BPagination, BFormGroup, BFormInput, BFormSelect,BFormSelectOption, BRow, BCol,BButton,BDropdown, BDropdownItem,BAlert
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from "vuex";
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import {required} from 'vuelidate/lib/validators';
import url from '@/shared/api/parametre';
import {is422} from '@/shared/utils/response'
import { list } from 'postcss';
export default {
  components: {
    BOverlay,
    BSidebar,
    Ripple,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BFormSelect,
    BCardText,
    BCard, 
    BCardHeader, 
    BCardTitle, 
    BCardBody,
    BFormCheckbox,
    BForm, 
    BFormSelectOption,
    BInputGroup,
    BInputGroupPrepend ,
    BRow,
    BCol,
    BButton,
    BDropdown, 
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BAlert
  },
  directives: {
   'b-toggle': VBToggle,
    Ripple,
  },
  props:['ria','paiement'],
  watch:{
    getItemsRisque: {
        immediate:true,
        handler(val,nld){
            this.rl=val.ItemsRisque[0]
            this.devis=val.ItemsRisque[0].devis
            this.get_list_Document_by_object()
        }
    },
    Tiers: {
        immediate:true,
        handler(val,nld){
          if(val.id)
          this.getDocumentByProspect(val.id,val.type)
        }
    },
    'document.famille_doc': function (old, nld) {
          if (this.document.famille_doc != null) {
            this.document_type = this.document.famille_doc.documentype
        }
    },
    documentsLocal: {
        deep:true,
        handler(val,nld){
            this.filtreDocuments(val)
        }
    },
  },
  computed: {
    ...mapGetters(["getItemsRisque"]),
    ...mapGetters(["Tiers"]),
    ...mapGetters(["Enfant"]),
    ...mapGetters(["Conjoint"]),
    ...mapGetters(["Entreprise"]),
    ...mapGetters(["getEtude"]),
    ...mapGetters(["BeneficieresData"]),
    ...mapGetters(["AutreBeneficiers"]),

    documentsDevisRib: function() {
      let docRib=_.find(this.documentsDevis, function(doc) { return doc.document && doc.document.document_type.commercial_type=='RIB' && doc.rib_type=='Paiement'; });
       return docRib ? docRib : null
    },
    documentsDevisRembRib: function() {
      let docRib=_.find(this.documentsDevis, function(doc) { return doc.document && doc.document.document_type.commercial_type=='RIB' && doc.rib_type=='Remboursement'; });
       return docRib ? docRib : null
    },
  },

  mounted() {
      this.get_list_nature_Document()
  },
  data() {
    return {
      disabled_btn: false,
      renderComponent:true,
      pageLength: 5,
      dir: false,
      options: [
        { value: null, text: 'Choisissez' },
        { value: 'Modifier', text: 'Modifier' },
        { value: 'Archiver', text: 'Archiver' },
        { value: 'Supprimer', text: 'Supprimer' },
      ],
      selected: null,
      headers: [
        {
          label: 'Famille',
          field: 'libelle',

        },
        {
          label: 'Type',
          field: 'type',

        },
        {
          label: 'Nom',
          field: 'nom_document',

        },
        {
          label: 'Actions',
          field: 'actions',

        }
      ],
      rows: [],
      searchTerm: '',
      errors:[],
      documents:[],
      interface_choosed:"Importer document depuis mon PC",
      document_nature:[],
      document: {
          date_creation: null,
          famille_doc: null,
          type_doc: null,
          note: null,
          nom_document: null,
          file: null,
          checked:false
      },
      getpj:[],
      document_type_search:[],
      getdatafiltredLocal:[],
      document_type:[],
      documentAttacherchoosed:[],
      errorDF:true,
      searchLocal:[],
      documentErrors:[],
      interface:'devis',
      devis:null,
      rl:null,
      errorsWsSouscription:[],
      isActive:false,
      documentsLocal:[],
      documentsDevis:[],
      documentsWithoutDevis:[],
      documentsFiltred:[],
      documentsFiltredModal:[],
      valueModal:null,
      showLoading:false,
      documentFile: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      },
      apiNotAvailable:false,
      suspected:false,
    }
  },
  validations: {
    document: {
        famille_doc: {required},
        type_doc: {required},
        nom_document: {required},
        file: {required}
    },
  },
  methods: {
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    displayDocument(id, name) {
      this.$http
          .get(`/document/generate_base64_for_document/${id}`)
          .then(res => {
            if (res.data.success) {
              this.clearDocumentDisplay()
              if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
                this.documentFile.base64 = res.data.data.base64
                this.documentFile.name =  name
                this.documentFile.nameToDownload = name
                this.documentFile.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
                this.documentFile.base64 = res.data.data.base64
                this.documentFile.name =  name
                this.documentFile.nameToDownload = name
                this.documentFile.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              }
            } else {
              this.clearDocumentDisplay()
              this.messageToast(res.data.message, 'Erreur', 'error')
            }
          })
          .catch(err => {
            this.clearDocumentDisplay()
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    clearDocumentDisplay() {
      this.documentFile.base64 = null
      this.documentFile.name = null
      this.documentFile.nameToDownload = null
      this.documentFile.extensionDocument = null
    },
    backStep(){
      this.errorsWsSouscription=[]
      this.$store.commit("setStepTarif", {
          step: {
            indexStep:3,
            active:'paiement'
          },
      });
    },
    openModal(value){
      this.documentsFiltredModal=_.filter(this.documentsFiltred, function(d)  { return d.document && d.document.document_type.commercial_type=='RIB'; });
      if(this.documentsFiltredModal.length>0){
        this.isActive=true
        this.valueModal=value
      }else{
          this.$toast({
            component: ToastificationContent,
            props: {
            title: 'Aucun '+value+' dans la GED',
            icon: 'EditIcon',
            variant: 'danger',
            },
          })
      }
    },
    openUploadModal(value){
        this.isActive=true
        this.valueModal=value
        this.document.type_doc = this.document_type.find(dt => dt.commercial_type =='RIB');
    },
    filtreDocuments(doc){
      if(doc && doc.length>0){
        let devisId=this.rl.devis.id
        this.documentsFiltred=_.uniqBy(doc, function (d) {return d.document_id;});
        this.documentsDevis=_.filter(doc, function(d)  { return d.devis_id==devisId; });
        // this.documentsWithoutDevis=_.filter(this.documentsFiltred, function(d) { return d.devis_id!==devisId; });
      }
    }, 
    affecterDocument(doc){
      if(doc.document.document_type.commercial_type=='RIB'){
        let devisId=this.rl.devis.id
        let data=null
        // let foundEmptyDoc = this.documentsLocal.find(el => el.id ==doc.id && el.devis_id==null);
        // if(foundEmptyDoc){
        //   data={
        //     type:'update',
        //     client_id:this.Tiers.id,
        //     client_type:this.Tiers.type,
        //     document_id:foundEmptyDoc.id,
        //     devis_id:devisId,
        //     etude_id:this.getEtude.etudeID,
        //     rib_type:this.valueModal == 'REMBOURSEMENT RIB' ? 'Remboursement' : 'Paiement'
        //   }
        // }else{
        data={
          type:'add',
          client_id:this.Tiers.id,
          client_type:this.Tiers.type,
          document_id:doc.id,
          devis_id:devisId,
          etude_id:this.getEtude.etudeID,
          rib_type:this.valueModal == 'REMBOURSEMENT RIB' ? 'Remboursement' : 'Paiement'
        }
        // }
        this.$http.post(`document/addorupdateDocumentClient`,data)
          .then(r => {
           this.documentsLocal=r.data
           this.isActive=false
          })
          .catch(e => {
          console.log(e)
          })
      }
    }, 
    get_list_nature_Document() {
    const fetchDocumentNatureWithTypes = documentService()
          fetchDocumentNatureWithTypes
            .then(r => {
                this.document_nature = r.data
                this.document.famille_doc=this.document_nature.find( dn => dn.id==3);
            })
            .catch(errors => {
              console.log(errors)
            })
    },
    get_list_Document_by_object() {
      this.$http.get(`ecaglobal/getlistdoccumentbytype/${this.interface}/${this.devis.id}`)
          .then(r => {
          this.getDataFormated(r.data)
          })
          .catch(e => {
          console.log(e)
          })
    },
    async getDocumentByProspect(id,type) {
      this.showLoading = true
      await this.$http.get(`document/getDocumentByProspect/${id}/${type}`)
          .then(r => {
            this.documentsLocal=r.data
            this.showLoading = false
          })
          .catch(e => {
          console.log(e)
          this.showLoading = false
          })
    },
    getDataFormated(data){
      let localData=[]
      let document=null
      Object.entries(data).forEach(([key, doc]) => {
          document={
              famille_doc:doc.document_type.document_nature,
              type_doc:doc.document_type,
              nom_document:doc.nom,
              date_creation:doc.date_creation_document,
              checked:false,
              path:doc.path,
              id_document:doc.id
          }
          localData.push(document);
      })
      this.getpj=localData
    },
    onFileChange(event) {
        this.document.file = this.$refs.file.files[0]
        this.document.nom_document=this.formatText(this.document.file.name, 'initial')
        this.openUploadModal(event)
        this.add(event)
        this.forceRerender()
    },
    onFileChangeRib(event) {
        this.document.file = this.$refs.fileRib.files[0]
        this.document.nom_document=this.formatText(this.document.file.name, 'initial')
        this.openUploadModal(event)
        this.add(event)
        this.forceRerender()
    },
    add(event) {
        this.$v.$reset()
        this.$v.document.$touch()
        if (this.$v.document.$error) {
            console.log(this.$v.document.$error)
            return 0
        }

        let pjLocal={
            date_creation: this.document.date_creation,
            famille_doc: this.document.famille_doc,
            type_doc: this.document.type_doc,
            note: this.document.note,
            nom_document: this.document.nom_document,
            file: this.document.file,
            checked: this.document.checked
        }

        this.submitDocument(pjLocal,event)

        this.document.date_creation = null
        //this.document.famille_doc = null
        this.document.type_doc = null
        this.document.note = null
        this.document.nom_document = null
        this.document.file = null

        this.$v.$reset()
    },
    submitDocument(pj,event){
      this.showLoading=true
      let config = {headers: {'Content-Type': 'multipart/form-data'}}
      let formData = new FormData()
      let prospectLocal=_.cloneDeep(this.Tiers)
      prospectLocal.type= prospectLocal.type =='PERSONNE_PHYSIQUE' ? 'PERSONNE_PHYSIQUE' : 'PERSONNE_MORALE'

      formData.append('type_tiers', prospectLocal.type)
      formData.append('id_tiers',prospectLocal.id)

      if(this.interface=='devis'){
          formData.append('devis_id', this.devis.id)
          formData.append('etude_id', this.getEtude.etudeID)
          formData.append('rib_type', event=='REMBOURSEMENT RIB' ? 'Remboursement' : 'Paiement')
      }
      
      let pjLocal=[]
      pjLocal.push(pj);

      if (pjLocal !== null) {
          if (pjLocal.length > 0) {
              for (var i = 0; i < pjLocal.length; i++) {
                  formData.append('files[' + i + '][file]', pjLocal[i].file)
                  formData.append('files[' + i + '][date_creation]', pjLocal[i].date_creation)
                  formData.append('files[' + i + '][note]', pjLocal[i].note)
                  formData.append('files[' + i + '][nom_document]', pjLocal[i].nom_document)
                  formData.append('files[' + i + '][type]', pjLocal[i].type_doc['id'])
                  formData.append('files[' + i + '][typenom]', pjLocal[i].type_doc['type'])
                  formData.append('files[' + i + '][nature]', pjLocal[i].type_doc['document_nature_id'])
              }
          }
      }

      this.$http.post(`ecaglobal/saveDocument`, formData, config).then(r => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Ajout document`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `Le document a été ajouté avec succès`,
          },
        })
        this.getDocumentByProspect(this.Tiers.id,this.Tiers.type)
        this.isActive=false
        this.showLoading=false
      }).catch(err => {
        this.showLoading=false
      })
    },
    async submit(){
        this.showLoading=true
        this.disabled_btn = true
        this.documentErrors=[]
        let produitPreconise = this.rl.produitsFiltred.find( pf => pf.tarif.choix_client);
        let customData={
            prospect:this.Tiers,
            devis:this.rl.devis,
            risque:this.rl.risque,
            produit:produitPreconise,
            pj:this.documentsDevis,
            paiement:this.paiement,
        }
        let detected=false
        if(this.documentsDevis.length>1){
          let listProspect=[]
          if(this.Tiers.statut_gda=="ORANGE" || this.Tiers.statut_gda=="ROUGE")
            detected=true
          if(this.rl.objets.objet_deces.conjoint)
              if(this.Conjoint.statut_gda=="ORANGE" || this.Conjoint.statut_gda=="ROUGE" )
                detected=true
          if(this.rl.objets.objet_deces.enfantCharge>0) {
            for(let i=0;i<this.Enfant.Enfant.length;i++) {
              if(this.Enfant.Enfant[i].statut_gda=="ORANGE" || this.Enfant.Enfant[i].statut_gda=="ROUGE")
                detected=true
            }
          }
          if(this.Tiers.statut_gda=="GRIS" || this.Tiers.statut_gda=="BLANC")
            listProspect.push(this.Tiers)
          if(this.rl.objets.objet_deces.conjoint)
            if(this.Conjoint.statut_gda=="GRIS" || this.Conjoint.statut_gda=="BLANC" )
              listProspect.push(this.Conjoint)
          if(this.rl.objets.objet_deces.enfantCharge>0) {
            for(let i=0;i<this.Enfant.Enfant.length;i++) {
              if(this.Enfant.Enfant[i].statut_gda=="GRIS" || this.Enfant.Enfant[i].statut_gda=="BLANC") {
                listProspect.push(this.Enfant.Enfant[i])
              }
            }
          }

          if(detected)
            this.detectedAlertMessage()
          else if(listProspect.length>0)
            this.showAlertGDA(customData,listProspect)
          else this.nextStep(customData)
        }
        else{
          this.messageToast("Ajoutez l'ensemble des pièces justificatives listées pour continuer", 'Erreur', 'error')
          this.showLoading=false
          this.disabled_btn = false
        }

    },
    async nextStep(customData){
      await this.$http.post(`portefeuilles/validateCustomDataStrategy`, customData).then((r) => {
            this.submitSouscripteur()
            // this.$store.commit("setStepTarif", {
            //   step: {
            //     indexStep:5,
            //     active:'signature'
            //   },
            // });
        }).catch((err)=>{
            if(is422(err))
            {
                this.documentErrors=err.response.data.errors
            }
            this.showLoading=false
        })
    },
    async updateDevisSouscriptionAvancement(){
      let data={
          id:this.rl.devis.id,
          devis_etape_avancement:'signature'
      }
      await this.$http.post(`devis/updateDevisSouscriptionState`,data).then((r) => {
          return  true
      }).catch((err)=>{
          console.log(err)
      })
    },
    async submitSouscripteur(){
      this.getItemsRisque.ItemsRisque[0].errors=[]
      let produitPreconise = this.getItemsRisque.ItemsRisque[0].produitsFiltred.find( pf => pf.tarif.choix_client);
      console.log('produitPreconise');
      let produitRecommande = this.getItemsRisque.ItemsRisque[0].produitsFiltred.find( pf => pf.tarif.preconise && pf.tarif.formule_commercial != produitPreconise.tarif.formule_commercial && pf.produit.id == produitPreconise.produit.id);
      let entrepriseLocal=_.find(this.Entreprise, function(entreprise) { return entreprise.choosedEntreprise; });
      let data= {
        etude:this.getEtude.etudeID,
        prospect:this.Tiers,
        enfant:this.Enfant,
        conjoint:this.Conjoint,
        entreprise:entrepriseLocal,
        devis:this.getItemsRisque.ItemsRisque[0].devis,
        objets:this.getItemsRisque.ItemsRisque[0].objets,
        exigences:this.getItemsRisque.ItemsRisque[0].exigences,
        produit:produitPreconise,
        produitRec: produitRecommande != undefined ? produitRecommande : null,
        risque:this.getItemsRisque.ItemsRisque[0].risque,
        paiement:this.paiement,
        ria:this.ria,
        pj:this.documentsDevis,
        Beneficieres :(!this.AutreBeneficiers && this.BeneficieresData !==[]) ? this.BeneficieresData : []
      }
      this.validTosouscrire(data);
    },
    async validTosouscrire(data){
      this.errorsWsSouscription=[];
      console.log('data before');
      console.log(data)
      await this.$http.post(`portefeuilles/creerDevisStrategy`,data).then((r) => {
          if(r.data.ba){
            this.getItemsRisque.ItemsRisque[0].devis.document = r.data.ba
            if(r.data.proposition) {
              this.getItemsRisque.ItemsRisque[0].devis.documentProposition = r.data.proposition
            }

            if(r.data.mandat){
              this.getItemsRisque.ItemsRisque[0].devis.documentMandat=r.data.mandat
            }
            // this.getItemsRisque.ItemsRisque[0].devis.documentFic=r.data.data.fic
            this.showLoading=false
            this.updateDevisSouscriptionAvancement()
            this.$store.commit("setStepTarif", {
              step: {
                indexStep:5,
                active:'signature'
              },
            });
          }else{
            console.log(r.data.retourws);
            this.errorsWsSouscription=r.data.retourws
            this.showLoading=false
          }
          this.disabled_btn = false

      }).catch((err)=>{
        if(is422(err))
          {
            this.getItemsRisque.ItemsRisque[0].errors=err.response.data.errors
          }
        this.showLoading=false
        this.disabled_btn = false

      })
    },
    removeAffecter(id){
      this.$http.get(`document/detachDocumentClient/${id}`)
        .then(r => {
          var index = _.findIndex(this.documentsLocal, {id: id});
          this.documentsLocal[index].devis_id=null
          //this.documentsLocal.splice(index, 1);
        })
        .catch(e => {
        console.log(e)
        })
    },
    ShowFile(doc) {
      console.log(doc)
      this.displayDocument(doc.document.id,doc.document.nom_custom)
      // var str = null
      // var path = null
      // let document= doc.document
      // str = document.path + document.nom
      // if (document.id) {
      //     path = str.replace('public', url + 'storage')
      //     path = path.replace('api/', '')
      // } else {
      //     path = URL.createObjectURL(document.file)
      // }
      // var width = 1000
      // var height = 600
      // var left = parseInt(screen.availWidth / 2 - width / 2)
      // var top = parseInt(screen.availHeight / 2 - height / 2)
      // var windowFeatures = 'width=' + width + ',height=' + height + ',status,resizable,left=' + left + ',top=' + top + 'screenX=' + left + ',screenY=' + top
      // window.open(path, 'subWind', windowFeatures)
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
      this.renderComponent = true;
      });
    },
    showAlertGDA(data,listclient){
      let str="Attention, le contrôle du gel des avoirs sera effectué juste après validation de cet écran. Assurez-vous que toutes les informations (nom, prénom et date de naissance) de votre client/prospect et des personnes rattachées soient correctes.</br></br>"
      str=str+"Si un ou plusieurs sont suspectés, vous ne pourrez pas finaliser la souscription sans avoir effectué une vérification préalable de leur identité.</br></br>"
      str=str+"Que souhaitez-vous faire ?"

      this.$swal({
        html:'<div style="text-align:justify;">'+str+'</div>',
        icon: 'warning',
        showCancelButton:true,
        confirmButtonText: 'Je souhaite procéder aux contrôles de mon client/prospect et des personnes rattachées',
        cancelButtonText: '<div style="color:red;">Je souhaite vérifier à nouveau les informations de mon client/prospect et des personnes rattachées. </div>',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger mt-1',
          },
        cancelButtonColor:'#ffffff'

      }).then((result) => {
        if(result.value){
          this.$http.post('/gda/checkClientGda',{prospect:listclient})
          .then(res=>{
            if(res.data){
              let result=res.data
              let id=result.id
              if(!result.pass)
              this.$router.push({
                name: 'gda-list',
                params: { redirected: true }
              })
              else this.nextStep(data)
            }
          }).catch(err=>{
            this.apiNotAvailable=true
            this.showLoading=false
          })
        }
      })
      this.showLoading=false
    },
    detectedAlertMessage(){
      this.suspected=true
      let chardd="Attention, un ou plusieurs bénéficiaires ont été suspectés d'être présents sur le registre du gel des avoirs.</br></br>"
      chardd=chardd+"Pour pouvoir finaliser la souscription, vous devez confirmer les identités de chacune des personnes suspectées.</br></br>"
      this.$swal({
        html:'<div style="text-align:justify;">'+chardd+'</div>',
        icon: 'warning',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
          },
      }).then(()=>{  
          this.$router.push({
            name: 'gda-list',
          })
      });
      this.disabled_btn = false

    }

  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style lang="scss" scoped>
.custom-icon-box{
  font-size: 16px;
  cursor: pointer;
}
.b-sidebar.sidebar-lg{
  width: 40% !important;
}
.btn-dropdown-custom{
    cursor: pointer;
    padding: 0.65rem 1.28rem;
    clear: both;
    text-align: inherit;
    background-color: transparent;
    border: 0;
}

</style>
