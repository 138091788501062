<template>
   <div>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">
            étape 2 : validation des bénéficiaires
        </h2>
        <h6>
           Vérifiez et complétez les informations relatives aux bénéficaires
        </h6>
      </b-col>
    </b-row>
    <b-card-text>
      <h4 class="title-custom-wizard"><u>ASSURÉ PRINCIPAL (Signataire)</u></h4>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Civilité*"
            label-for="civilite"
          >
            <b-form-select
              v-model="prospect.civilite"
              id="civilite"
            >
              <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
              <b-form-select-option value="MR">M.</b-form-select-option>
              <b-form-select-option value="MME">Mme</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback :state="!$v.prospect.civilite.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Nom*"
            label-for="nom"
          >
            <!-- <b-form-input 
              id="nom"
              v-model="prospect.nom"
              
              autocomplete="nope"
              trim
              placeholder="Nom"
              class="text-uppercase"
            /> -->

            <div class="form-control" readonly disabled v-if="prospect.statut_gda==='VERT' || prospect.statut_gda==='ROUGE' || prospect.statut_gda==='ORANGE' ">{{ prospect.nom }}</div>
            <b-form-input v-else
              id="nom"
              v-model="prospect.nom"
              autocomplete="nope"
              trim
              placeholder="Nom"
              class="text-uppercase"
            />
            <b-form-invalid-feedback :state="!$v.prospect.nom.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Nom de naissance*"
            label-for="nom_naissance"
          >
            <b-form-input
              id="nom_naissance"
              v-model="prospect.nom_naissance"
              autocomplete="nope"
              trim
              placeholder="Nom de naissance"
              class="text-uppercase"
            />
            <b-form-invalid-feedback :state="!$v.prospect.nom_naissance.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Prénom*"
            label-for="prenom"
          >
            <!-- <b-form-input  
              id="prenom"
              v-model=""
              disabled
              autocomplete="nope"
              trim
              placeholder="Prénom"
              class="text-capitalize"
            /> -->
            <div class="form-control" readonly disabled v-if="prospect.statut_gda==='VERT' || prospect.statut_gda==='ROUGE' || prospect.statut_gda==='ORANGE' ">{{ prospect.prenom }}</div>
            <b-form-input  v-else
              id="prenom"
              v-model="prospect.prenom"
              autocomplete="nope"
              trim
              placeholder="Prénom"
              class="text-capitalize"
            />
          <b-form-invalid-feedback :state="!$v.prospect.prenom.$error">
            Veuillez renseigner le(s) champ(s) obligatoire(s)
          </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Date de naissance*"
            label-for="date_naissance"
          >
            <!-- <b-form-input  v-if="prospect.statut_gda==='VERT'"
              id="date_naissance"
              readonly
              disabled
              v-model="prospect.date_naissance"
              autocomplete="nope"
              trim
              placeholder="Date de naissance"
              class="text-capitalize"
            /> -->
            <div class="form-control" readonly>{{ prospect.date_naissance }}</div>
            <!-- <b-form-input  v-else
              id="date_naissance"
              readonly
              v-model="prospect.date_naissance"
              autocomplete="nope"
              trim
              placeholder="Date de naissance"
              class="text-capitalize"
            /> -->
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Code postal de naissance*"
            label-for="code_postal_naissance"
          >
            <b-form-input
              id="code_postal_naissance"
              v-model="prospect.code_postal_naissance"
              autocomplete="nope"
              trim
              placeholder="Code postal de naissance"
              class="text-capitalize"
            />
            <b-form-invalid-feedback :state="!$v.prospect.code_postal_naissance.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Commune de naissance*"
            label-for="commune_naissance"
          >
            <b-form-input
              id="commune_naissance"
              v-model="prospect.commune_naissance"
              autocomplete="nope"
              trim
              placeholder="Commune de naissance"
              class="text-capitalize"
            />
            <b-form-invalid-feedback :state="!$v.prospect.commune_naissance.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Pays de naissance*" label-for="pays">
            <v-select v-model="prospect.pays_naissance" :close-on-select="true" :reduce="pays => pays.pays"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays"
                      class="flex-grow-1"
                      input-id="pays" label="pays" placeholder="Pays de naissance"
            >
              <template #option="{ pays }">
                <span class="ml-50"> {{ pays }}</span>
              </template>

              <template #selected-option="{ pays }">
                <span class="ml-50"> {{ pays }}</span>
              </template>
              <div slot="no-options">Aucune pays disponible.</div>
            </v-select>
            <b-form-invalid-feedback :state="!$v.prospect.pays_naissance.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Nationalité*" label-for="nationalite">
            <v-select v-model="prospect.nationalite" :close-on-select="true" :reduce="nationalite => nationalite.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="nationalites"
                      class="flex-grow-1"
                      input-id="value" label="value" placeholder="Nationalité"
            >
              <template #option="{ value }">
                <span class="ml-50"> {{ value }}</span>
              </template>

              <template #selected-option="{ value }">
                <span class="ml-50"> {{ value }}</span>
              </template>
              <div slot="no-options">Aucune nationalité disponible.</div>
            </v-select>
            <b-form-invalid-feedback :state="!$v.prospect.nationalite.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
            <b-form-group
              label="Situation familiale*"
              label-for="situation_familiale"
            >
              <b-form-select
              v-model="prospect.situation_familiale"
              id="situation_familiale"
              >
                <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                <b-form-select-option v-for="(val, value) in situationFamiliale" :key="value" :value="val.value">{{val.name}}</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!$v.prospect.situation_familiale.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
            </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Catégorie Socioprofessionnelle*" label-for="activite_professionnelle">
              <b-form-select v-model="prospect.statut" id="activite_professionnelle" :state="errors.length > 0 ? false : null">
                <b-form-select-option v-for="(val, value) in statutJuridique" :key="value" :value="val.value">{{val.text}}</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!$v.prospect.statut.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
           <b-col md="3">
        <b-form-group
            label="Nombre d'enfant*"
            label-for="enfant_charge"
          >
            <cleave
                id="enfant_charge"
                v-model="prospect.enfantCharge"
                autocomplete="nope"
                type="number"
                class="form-control"
                :raw="false"
                :max="8"
                :options="options.enfCharge"
                placeholder="Nombre d'enfant"
                @input="validateEnfantCharge"
                
              />
            <b-form-invalid-feedback :state="!$v.prospect.enfantCharge.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="3">
                  <b-form-group label="Secteur d’activité*" label-for="secteur_activite">
                      <b-form-select v-model="prospect.secteur_activite" id="secteur_activite" :state="errors.length > 0 ? false : null">
                        <b-form-select-option value="null" disabled>Choisissez</b-form-select-option>
                        <template v-for="secteur in sortedSecteurs">
                          <b-form-select-option :value="secteur.value" :key="secteur.value">{{ secteur.name }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <b-form-invalid-feedback :state="!$v.prospect.secteur_activite.$error">
                        Veuillez renseigner le(s) champ(s) obligatoire(s)
                      </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
          <b-col md="4">
            <b-form-group
              label="N° et libellé de la voie*"
              label-for="libelle"
            >
              <b-form-input
                id="libelle"
                v-model="prospect.moyen_contact.libelle"
                autocomplete="nope"
                trim
                placeholder="N° et libellé de la voie"
              />
            <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.libelle.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group
              label="Complément d'adresse"
              label-for="complement_adresse"
            >
              <b-form-input
                id="complement_adresse"
                v-model="prospect.moyen_contact.complement_adresse"
                autocomplete="nope"
                trim
                placeholder="Complément d'adresse"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Lieu-dit"
              label-for="lieu_dit"
            >
              <b-form-input
                id="lieu_dit"
                v-model="prospect.moyen_contact.lieu_dit_ou_bp"
                autocomplete="nope"
                trim
                placeholder="Lieu-dit"
              />
              <!-- <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.lieu_dit_ou_bp.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback> -->
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Code postal*"
              label-for="code_postale"
            >
              <cleave
                id="code_postale"
                v-model="prospect.moyen_contact.code_postal"
                autocomplete="nope"
                type="number"
                class="form-control"
                :raw="false"
                :options="options.cp"
                placeholder="Code postal"
                
              />
              <!-- <div class="form-control" readonly disabled>{{ prospect.moyen_contact.code_postal }}</div> -->
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Ville*"
              label-for="ville"
            >
              <b-form-select
              v-model="prospect.moyen_contact.ville_id"
              id="ville"
              >
                <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                <b-form-select-option v-for="(val, value) in villesLocal" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.ville_id.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Numéro de téléphone " label-for="phone">
            <div class="d-flex bd-highlight">
              <div class="w-25 bd-highlight">
                <v-select v-model="$v.prospect.moyen_contact.indicatif_tel.$model" :close-on-select="true" :clearable="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"
                  :reduce="indicatif => indicatif.indicatif" input-id="indicatif" label="indicatif" class="indicatif-chooser-souscription-santesenior w-100">
                  <template #option="{ indicatif,code }">
                    <span> {{`${code.toUpperCase()} ` + indicatif }}</span>
                  </template>
                  <template #selected-option="{ indicatif }">
                    <span> {{ indicatif }}</span>
                  </template>
                  <div slot="no-options">Aucune indicatif disponible.</div>
                </v-select>
              </div>
              <div class="w-75 bd-highlight">
                  <cleave id="phone" v-model="$v.prospect.moyen_contact.tel.$model" :options="options.phone" :raw="false" class="form-control" style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;" placeholder="ex: 06 00 00 00 00" />
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight">
                <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.tel.$error"> Le numéro de téléphone est obligatoire </b-form-invalid-feedback>
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight">
                <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.indicatif_tel.$error"> L'indicatif téléphonique est obligatoire </b-form-invalid-feedback>
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="w-100 bd-highlight">
                <b-form-invalid-feedback :state="false"> Attention, un numéro de téléphone portable valide est nécessaire pour la signature du contrat. </b-form-invalid-feedback>
              </div>
            </div>
          </b-form-group>
          </b-col>
        </b-row>
    </b-card-text>

      <b-card-text v-if="objets.objet_deces.conjoint">
        <h4 class="title-custom-wizard"><u>CONJOINT</u></h4>
        <b-row>
          <b-col md="3">
            
            <b-form-group
              label="Civilité*"
              label-for="civiliteConjoint"
            >
              <b-form-select
                v-model="ConjointLocal.civilite"
                id="civiliteConjoint"
              >
                <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                <b-form-select-option value="MR">M.</b-form-select-option>
                <b-form-select-option value="MME">Mme</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!$v.ConjointLocal.civilite.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="!hasErrors('conjoint.civilite')">
                  Veuillez renseigner le(s) champ(s) obligatoire(s
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Nom*"
              label-for="nom_conjoint"
            >
            <div class="form-control" readonly disabled v-if="ConjointLocal.statut_gda=='ORANGE' || ConjointLocal.statut_gda=='ROUGE' || ConjointLocal.statut_gda=='VERT'">{{ ConjointLocal.nom }}</div>
              <b-form-input v-else
                class="text-uppercase"
                id="nom_conjoint"
                v-model="ConjointLocal.nom"
                trim
                placeholder="Nom"
              />
              <b-form-invalid-feedback :state="!$v.ConjointLocal.nom.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="!hasErrors('conjoint.nom')">
                  Veuillez renseigner le(s) champ(s) obligatoire(s).
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Prénom*"
              label-for="prenom_conjoint"
            >
            <div class="form-control" readonly disabled v-if="ConjointLocal.statut_gda=='ORANGE' || ConjointLocal.statut_gda=='ROUGE' || ConjointLocal.statut_gda=='VERT'">{{ ConjointLocal.prenom }}</div>
              <b-form-input v-else
                class="text-capitalize"
                id="prenom_conjoint"
                v-model="ConjointLocal.prenom"
                trim
                placeholder="Prénom"
              />
               <b-form-invalid-feedback :state="!$v.ConjointLocal.prenom.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="!hasErrors('conjoint.prenom')">
                  Veuillez renseigner le(s) champ(s) obligatoire(s).
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- Nom de naissance -->
          <b-col md="3">
            <b-form-group label="Nom de naissance *" label-for="assure_nom_naissance">
                <b-form-input v-model="ConjointLocal.nom_naissance" id="assure_nom_naissance" placeholder="Nom de naissance" type="text" />
                <b-form-invalid-feedback :state="!$v.ConjointLocal.nom_naissance.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Date de naissance*"
              label-for="date_naissance_conjoint"
            >
              <!-- <b-form-input
                class="form-control"
                readonly
                disabled
                v-model="ConjointLocal.date_naissance"
                id="date_naissance_conjoint"
                :config="config"
                placeholder="Date naissance"
              /> -->
              <div class="form-control" readonly disabled >{{ ConjointLocal.date_naissance }}</div>
            </b-form-group>
          </b-col>
              <!-- Code postal de naissance -->
              <!-- <b-col md="3">
                <b-form-group label="Code postal de naissance *" label-for="code-postal-naissance">
                    <b-form-input id="code-postal-naissance" v-model="ConjointLocal.code_postal_naissance" type="text" :raw="false" placeholder="Code postal de naissance" />
                    <b-form-invalid-feedback :state="!$v.ConjointLocal.code_postal_naissance.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
                </b-form-group>
              </b-col> -->
              <!-- Commune de naissance -->
              <!-- <b-col md="3">
                <b-form-group label="Commune de naissance *" label-for="commune-naissance">
                    <b-form-input v-model="ConjointLocal.commune_naissance" class="text-capitalize" id="commune-naissance" placeholder="Commune de naissance" type="text" />
                    <b-form-invalid-feedback :state="!$v.ConjointLocal.commune_naissance.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
                </b-form-group>
              </b-col> -->
              <!-- Pays de naissance -->
              <!-- <b-col md="3">
                  <b-form-group label="Pays de naissance *" label-for="pays-naissance">
                    <v-select v-model="ConjointLocal.pays_naissance" :close-on-select="true" :reduce="pays => pays.pays"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays"
                                      class="flex-grow-1"
                                      input-id="pays" label="pays" placeholder="Pays de naissance"
                            >                      
                      <template #option="{ pays }">
                        <span class="ml-50"> {{ pays }}</span>
                      </template>

                      <template #selected-option="{ pays }">
                        <span class="ml-50"> {{ pays }}</span>
                      </template>
                      <div slot="no-options">Aucune pays disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="!$v.ConjointLocal.pays_naissance.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
                  </b-form-group>
              </b-col> -->
              <b-col md="3">
                <b-form-group label="Nationalité*" label-for="nationalite">
                  <v-select v-model="ConjointLocal.nationalite" :close-on-select="true"
                    :reduce="nationalite => nationalite.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="nationalites" class="flex-grow-1" input-id="value" label="value"
                    placeholder="Nationalité">
                    <template #option="{ value }">
                      <span class="ml-50"> {{ value }}</span>
                    </template>

                    <template #selected-option="{ value }">
                      <span class="ml-50"> {{ value }}</span>
                    </template>
                    <div slot="no-options">Aucune nationalité disponible.</div>
                  </v-select>
                  <b-form-invalid-feedback :state="!$v.ConjointLocal.nationalite.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
        </b-row>
        
      </b-card-text>
    <b-card-text>
        <b-row class="mb-3">
          <b-col md="12" sm="12" class="mb-2">
            <h4 class="title-custom-wizard mt-2"><u>Bénéficiaire en cas de décès accidentel</u></h4>
          </b-col>
          <b-col md="12" sm="12">
            <div>
              <b-row>
                <div class="col-12 mb-1">
                  <span style="font-size:14px">En cas de décès, le souscripteur souhaite que le capital garanti soit versé :</span>
                </div>
              </b-row>
              <b-row>
                <b-col md="12">
                   <b-form-group>
                    <b-form-radio-group
                      v-model="autreBeneficiers"
                      :options="BooleanValueBenif"
                      label="bénéficiaire à ajouter"
                      class="d-flex"
                      required
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-for="(benef, index) in beneficieresData" :key="index" class="mb-3 ml-1" v-if="!autreBeneficiers">
                <b-col md="12" sm="12" class="mb-2">
                  <h4 class="title-custom-wizard mt-2"><u>Bénéficiaire {{index+1}} : </u></h4>
                </b-col>
                <b-col md="0" class="d-inline text-center" style="margin-top:30px">
                  <b-form-group class="d-inline">
                    <b-form-checkbox v-model="benef.state"  class="d-inline"></b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group
                    label="Civilité*"
                    :label-for="`civilite ${index}`"
                  >
                    <b-form-select
                      v-model="benef.civilite"
                      :id="`civilite ${index}`"
                    >
                      <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                      <b-form-select-option value="MR">M.</b-form-select-option>
                      <b-form-select-option value="MME">Mme</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback :state="!$v.beneficieresData.$each[index].civilite.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>

                    <b-form-invalid-feedback :state="!hasErrors('beneficiere.' + index + '.civilite')">
                      Veuillez renseigner le(s) champ(s) obligatoire(s).
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <label for="nom">Nom :</label>
                    <b-form-input id="nom" v-model="benef.nom" autocomplete="nope" type="text" placeholder="Nom" class="text-capitalize" />
                    <b-form-invalid-feedback :state="!$v.beneficieresData.$each[index].nom.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <label for="prenom">Prénom :</label>
                    <b-form-input id="prenom" v-model="benef.prenom" autocomplete="nope" type="text" placeholder="Prénom" class="text-capitalize" />
                    <b-form-invalid-feedback :state="!$v.beneficieresData.$each[index].prenom.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="3" class="ml-2">
                  <b-form-group>
                    <label for="date_naissance-benef">Date de naissance :</label>
                    <flat-pickr id="date-naissance-benef" v-model="benef.date_naissance" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDateNaissance" class="form-control" placeholder="Date de naissance" rules="required" autocomplete="off" />
                    <b-form-invalid-feedback :state="!$v.beneficieresData.$each[index].date_naissance.$error">
                        Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <label for="lieu_naissance">Lieu de naissance :</label>
                    <b-form-input id="lieu_naissance" v-model="benef.lieu_naissance" autocomplete="nope" type="text" placeholder="Lieu de Naissance" class="text-capitalize" />
                   <b-form-invalid-feedback :state="!$v.beneficieresData.$each[index].lieu_naissance.$error">
                        Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Lien Parenté" label-for="h-LienParente">
                      <b-form-select id="h-LienParente" v-model="benef.lien_parente" :options="optionsLienParente" size="md" :state="errors.length > 0 ? false : null" />
                     <b-form-invalid-feedback :state="!$v.beneficieresData.$each[index].lien_parente.$error">
                        Veuillez renseigner le(s) champ(s) obligatoire(s)
                      </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group>
                    <label for="quota">Quota part % :</label>
                     <cleave
                        id="quota"
                        v-model="benef.quota"
                        autocomplete="nope"
                        type="number"
                        class="form-control"
                        :raw="false"
                        :max="100"
                        :options="options.quota"
                        placeholder="Quota"
                        
                      />
                    <!-- <b-form-input id="quota" v-model="benef.quota" autocomplete="nope" type="number" placeholder="Quota" class="text-capitalize" /> -->
                  <b-form-invalid-feedback :state="!$v.beneficieresData.$each[index].quota.$error">
                       Veuillez renseigner le(s) champ(s) obligatoire(s).
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="0" class="text-center" style="margin-top:30px" >
                  <div class="d-inline" @click="removeBenef(index)">
                    <feather-icon icon="XIcon" size="22" class="mr-50 ml-0" />
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col md="12" sm="12" v-if="!autreBeneficiers" class="mt-1">
            <b-button @click="repeateAgain" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="outline-primary">
              <feather-icon icon="PlusIcon" />
              <span class="align-middle">Ajouter un bénéficiaire</span>
            </b-button>
          </b-col>
        </b-row>
    </b-card-text>
    <b-card-text v-if="objets.objet_deces.ria">
        <h4 class="title-custom-wizard"><u>INFORMATIONS RIA</u></h4>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nom de l'assureur*"
              label-for="raison_socialeE"
            >
              <b-form-input
                id="raison_socialeE"
                v-model="riaLocal.raisonSociale"
                trim
                placeholder="Nom de l'assureur"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.raisonSociale')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Service de résiliation*"
              label-for="serviceResiliationE"
            >
              <b-form-input
                id="serviceResiliationE"
                v-model="riaLocal.serviceResiliation"
                trim
                placeholder="Service de résiliation"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.serviceResiliation')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Adresse*"
              label-for="adresseE"
            >
              <b-form-input
                id="adresseE"
                v-model="riaLocal.adresse"
                trim
                placeholder="Adresse"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.adresse')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Code postal*"
              label-for="codePostalE"
            >
              <cleave
                id="codePostalE"
                v-model="riaLocal.codePostal"
                autocomplete="nope"
                type="number"
                class="form-control"
                :raw="false"
                :options="options.cp"
                placeholder="Code postal"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.codePostal')">
                <span v-if="hasErrors('ria.codePostal')=='Veuillez renseigner le(s) champ(s) obligatoire(s)'">Veuillez renseigner le(s) champ(s) obligatoire(s).</span>
                <span v-else>Le code postal sociale est erroné. </span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
                label="Ville*"
                label-for="villeE"
              >
                <b-form-select
                v-model="riaLocal.ville_id"
                id="villeE"
                >
                  <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                  <b-form-select-option v-for="(val, value) in riaLocal.villesData" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback :state="!hasErrors('ria.ville_id')">
                  Veuillez renseigner le(s) champ(s) obligatoire(s).
                </b-form-invalid-feedback>
              </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Nom du contrat*"
              label-for="contratNomE"
            >
              <b-form-input
                id="contratNomE"
                v-model="riaLocal.contratNom"
                trim
                placeholder="Nom du contrat"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.contratNom')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Numéro de contrat*"
              label-for="contratNumE"
            >
              <b-form-input
                id="contratNumE"
                v-model="riaLocal.contratNum"
                trim
                placeholder="Numéro de contrat"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.contratNum')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Date d'echeance*"
              label-for="echeance"
            >
              <flat-pickr
                class="form-control"
                v-model="riaLocal.echeance"
                id="echeance"
                :config="config"
                placeholder="Date d'echeance"
              />
              <b-form-invalid-feedback :state="!hasErrors('ria.echeance')">
                Veuillez renseigner le(s) champ(s) obligatoire(s).
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

        </b-row>
    </b-card-text>


    <b-alert variant="danger" show v-show="objets.objet_deces.ria && assureurProduitChoisie" class="mt-1">
          <div class="alert-body">
            <p>Nous vous rappelons qu’un mandat de résiliation sera proposé à la signature en fin de parcours et devra être adressé par vos soins à l’ancien assureur.</p>
            
          </div>
    </b-alert>


    <b-card-text>
        <!-- <h4 class="title-custom-wizard"><u>DONNÉES CONTRACTUELLES</u></h4> -->
        <b-row>
          <!-- <b-col md="6">
            <label>Loi Madelin</label>
            <b-form-radio-group
              label="Le proposant est-il éligible au cadre du RIA?"
              name="madelin"
              v-model="devis.loi_madelin"
              :options="BooleanValue"
            ></b-form-radio-group>
          </b-col> -->

          <b-col lg="12" class="mt-4">
            <b-button
              variant="primary"
              class="float-left"
              size="lg"
              @click="backStep"
            >
              Précédent
            </b-button>
            <b-button
              variant="primary"
              class="float-right"
              size="lg"
              @click="submit"
            >
              Suivant
            </b-button>
          </b-col>
        </b-row>
    </b-card-text>

   </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from "vuex";
import {required,minLength,maxLength,numeric,email, requiredIf} from "vuelidate/lib/validators";
import Data from "@/shared/constants/risquebesoin";
import {is422} from '@/shared/utils/response';
import moment from 'moment';
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import BCardCode from '@core/components/b-card-code';
import Ripple from 'vue-ripple-directive';
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  // BCardCode,
  BFormSelectOption,
  BAlert,
  BForm,
  BOverlay,
  BButtonGroup,
  BFormCheckbox

} from "bootstrap-vue";
import { integer } from 'vee-validate/dist/rules';

//import { codeIcon } from './code'

export default {
  directives: {
    Ripple
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ToastificationContentVue,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormSelectOption,
    Cleave,
    BAlert,
    BForm,
    BOverlay,
    BButtonGroup,
    BFormCheckbox
  },
  props:["ria","pays","showLoading"],
  computed: {
    ...mapGetters(["Tiers"]),
    ...mapGetters(["Conjoint"]),
    ...mapGetters(["Enfant"]),
    ...mapGetters(["Beneficiers"]),
    ...mapGetters(["getItemsRisque"]),
  //  hasPerToCheck: function () {
  //     let beneficiereChecked = _.filter(this.beneficieresData, benef => benef.state);
  //     const totalQuota = beneficiereChecked.reduce((total, benef) => total + parseFloat(benef.quota || 0), 0);
  //     return beneficiereChecked && totalQuota >100
  //   },
    prospect_regime_obligatoire_mapped:function(){
        let findRegime = this.regimeSocial.find(obj => {
           return obj.value === this.prospect.regime_obligatoire;
        }); 
        return findRegime ? findRegime.name : ''
    },
    conjoint_regime_obligatoire_mapped:function(){
        let findRegime = this.regimeSocial.find(obj => {
           return obj.value === this.ConjointLocal.regime_obligatoire;
        }); 
        return findRegime ? findRegime.name : ''
    },
    formuleSelectedHasEnfnat :function(){
      let result=false
      let pr= _.find(this.rl.produitsFiltred, function(p) { return p.tarif.choix_client; });
      if(pr && (pr.produit.id == 19 || pr.produit.id  == 27 || pr.produit.id  == 23 ) ){
          result = true
      }
      return result
    },
    formuleSelectedHasBeneficiare :function(){
      let result=false
      let pr= _.find(this.rl.produitsFiltred, function(p) { return p.tarif.choix_client; });
      if(pr && pr.produit.id == 31  ){
          result = true
      }
      return result
    },
    sortedSecteurs() {
      return this.secteurActivite.sort((a, b) => a.name.localeCompare(b.name));
    },
    assureurProduitChoisie :function(){
      let result = false
      let pr = _.find(this.rl.produitsFiltred, function(p) { return p.tarif.choix_client; });
      if(pr && pr.produit.assureur_id == 905){
        return result = true
      }
      return result
    }
  },
  watch: {
    Tiers: {
        handler: function (val, oldVal) {
          if(val.id != undefined) {
            this.mapProspectLocal(val)
          }
        },
        immediate: true,
        deep:true
    },
    Conjoint: {
        handler: function (val, oldVal) {
          this.ConjointLocal = val;
        },
        immediate: true,
        deep:true
    },

    Enfant: {
        handler: function (val, oldVal) {
          this.EnfantsGlobalLocal = val;
        },
        immediate: true,
    },
    getItemsRisque: {
        handler: function (val, oldVal) {
          this.rl=val.ItemsRisque[0]
          this.objets=val.ItemsRisque[0].objets
          this.devis=val.ItemsRisque[0].devis
          this.getBeneficiereDevisData()
          if (this.objets.objet_deces.autreBeneficieres === null) {
            this.autreBeneficiers = true;
          } else {
              this.autreBeneficiers = !this.objets.objet_deces.autreBeneficieres;
          }
        },
      deep: true,
      immediate: true
    },
    "prospect.moyen_contact.code_postal": {
      immediate:true,
      handler(old,nld){
          this.cpKeyup()
      }
    },
    "riaLocal.codePostal": {
      immediate:true,
      handler(old,nld){
          this.cpKeyupRia()
      }
    },
    ria: {
      immediate:true,
      handler(val,nld){
          this.riaLocal=val
      }
    },
    getSelectedRisque: function (val, old) {
      this.indexRisque = this.getSelectedRisque;
    },
   
  },
  data() {
    return {
      updateStateBenfi: false,
      messageErreurConjoint: '',
      autreBeneficiers: true,
      ConjointLocal:null,
      devis:{
        loi_madelin:null,
      },
      objets:{
        objet_deces:{
          conjoint:null,
          enfantCharge:null,
        }
      },
      indicatif:[],
      BooleanValue: [
        {
          value: 0,
          text: 'Oui'
        },
        {
          value: 1,
          text: 'Non'
        }
      ],
      optionsCivilite: [
        {
          value: "MR",
          text: 'MR'
        },
        {
          value: "MME",
          text: 'MME'
        }
      ],
      optionsLienParente: [
        {
          value: null,
          text: '-- Choisissez --'
        },
        {
          value: "PERE",
          text: 'Père'
        },
        {
          value: "MERE",
          text: 'Mère'
        },
        {
          value: "FILS",
          text: 'Fils'
        },
        {
          value: "FILLE",
          text: 'Fille'
        },
        {
          value: "ONCLE",
          text: 'Oncle'
        },
        {
          value: "TANTE",
          text: 'Tante'
        },
        {
          value: "NEVEU",
          text: 'Neveu'
        },
        {
          value: "NIECE",
          text: 'Nièce'
        },
        {
          value: "GRANDPERE",
          text: 'Grand-père'
        },
        {
          value: "GRANDMERE",
          text: 'Grand-mère'
        },
        {
          value: "PETITFILS",
          text: 'Petit-fils'
        },
        {
          value: "PETITEFILLE",
          text: 'Petite-fille'
        },
        {
          value: "CONJOINT",
          text: 'Conjoint'
        },
        {
          value: "AMI",
          text: 'Ami'
        },
        {
          value: "FRERE",
          text: 'Frère'
        },
        {
          value: "SOEUR",
          text: 'Sœur'
        },
        {
          value: "AUTRE",
          text: 'Autre'
        },
      ],
      ayantdroitList: [
        {
          value: 'sans',
          text: '-- Choisissez --'
        },
        {
          value: 'signataire',
          text: 'Signataire'
        }
      ],
      prospect:{
        id:null,
        civilite:null,
        nom:null,
        prenom:null,
        date_naissance:null,
        type:null,
        numeroOrganisme:null,
        ayantdroit:null,
        situation_familiale:null,
        statut_gda:null,
        nom_naissance:null,
        code_postal_naissance:null,
        commune_naissance:null,
        pays_naissance:null,
        regime_obligatoire:null,
        rang_laser: 1,
        statut: null,
        original_statut: null,
        nationalite: null,
        secteur_activite:null,
        isPPE:null,
        enfantCharge:null,
        moyen_contact:{
          libelle:null,
          lieu_dit_ou_bp:null,
          complement_adresse:null,
          indicatif_tel: null,
          tel:null,
          code_postal:null,
          ville_id:null,
          email:null
        }
      },
      BooleanValue: [
        { value: 1, text: "Oui" },
        { value: 0, text: "Non" },
      ],
       BooleanValueBenif: [
        {
          value: true,
          text: "au conjoint marié ou Pacsé - à défaut les enfants de l'assuré nés ou à naitre vivants ou représentés par parts égales - à défaut les héritiers légaux de l'assuré."
        },
        {
          value: false,
          text: "à d'autres bénéficiaires nommément désignés en précisant la répartition du capital entre parts égales."
        }
      ],
      villesLocal:[],
      situationFamiliale : Data["SITUATIONFAMILIALE"],
      nationalites: Data["NATIONALITES"],
      statutJuridique : Data["ACTIVITEPROFESSIONNELLE"],
      regimeSocial : Data["REGIMESOCIAL"],
      secteurActivite : Data["SECTEUR_ACTIVITES"],
      errors:[],
      rl:null,
      EnfantsGlobalLocal:[],
      BeneficiersGlobalLocal:[],
      beneficieresData: [],
      enfantsData:[],
      disableEnfantNumSec: false,
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          numericOnly: true
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        block: {
          blocks: [9],
          uppercase: true,
        },
        blockSecu: {
          blocks: [15],
          uppercase: true,
        },
        blockSiret: {
          blocks: [14],
          uppercase: true,
        },
        delimiter: {
          delimiter: ' ',
          blocks: [2,2,2,2,2],
          uppercase: true,
          numericOnly:true
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+33',
          blocks: [3, 9],
          uppercase: true,
        },
        cp: {
          blocks: [5],
        },
        enfCharge: {
          blocks: [1],
        },
        quota: {
          blocks: [3],
        },
      },
  configDateNaissance: {
        dateFormat: 'Y-m-d',
        disable: [{
          from: moment().toDate(),
          to: '25/02/3000'
        }],
        altInput: true,
        altFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement
            var val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      // configDateNaissance: {
      //   dateFormat: 'Y-m-d', // Format pour envoyer la date
      //   altInput: true,
      //   onClose: this.handleClose, // Gestionnaire de fermeture du calendrier
      //   altFormat: 'd/m/Y', // Format pour afficher la date
      //   allowInput: true, // Permet l'entrée manuelle
      //   disable: [{
      //     from: moment().toDate(),
      //     to: '25/02/3000'
      //   }],
      //    onChange: this.handleDateChange, // Gestionnaire de changement personnalisé
      //      onKeyDown: (selectedDates, dateStr, instance, event) => {
      //     if (event.keyCode !== 8) {
      //       const ele = event.target;
      //       let val = ele.value;

      //       if ((val.length === 2 || val.length === 5) && ele.getAttribute('id')) {
      //         val += "/";
      //       }
      //       ele.value = val;
      //       ele.dispatchEvent(new Event("input"));
      //     }
      //   }
      // },
      // nassBenef : {
      //   dateFormat: 'd/m/Y',
      //   allowInput: true,
      //     onKeyDown: (selectedDates, dateStr, instance, event) => {
      //     if (event.keyCode != 8) {
      //       var ele = document.activeElement;
      //       var val = ele.value;

      //       if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
      //         val += "/";
      //       }
      //       ele.value = val;
      //       ele.dispatchEvent(new Event("input"));
      //     }
      //   },

   
      // },
      config: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement;
            var val = ele.value;

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += "/";
            }
            ele.value = val;
            ele.dispatchEvent(new Event("input"));
          }
        },     
      },
      riaLocal:{
        id:null,
        siret:null,
        raisonSociale:null,
        contratNum:null,
        contratNom:null,
        serviceResiliation:null,
        echeance:null,
        adresse:null,
        codePostal:null,
        ville_id:null,
        villesData:[]
      },
    };
  },
  validations: {
    autreBeneficiers: { required },
    ConjointLocal: {
      civilite: { required },
      nom: { required },
      prenom: { required },
      date_naissance: { required },
      nationalite: { required },
      nom_naissance: { required },
      // code_postal_naissance: { required },
      // commune_naissance: { required },
      // pays_naissance: { required },
    },
    enfantsData: {
      $each: {
        civilite: { required },
        nom: { required },
        prenom: { required },
        date_naissance: { required },
      },
    },
    beneficieresData: {
      $each: {
        civilite: { required },
        nom: { required },
        prenom: { required },
        date_naissance: { required },
        lieu_naissance: { required },
        lien_parente: { required },
        quota: { required},
      },
    },
    prospect: {
      moyen_contact: {
        tel: { required, minLength: minLength(14) },
        indicatif_tel: { required },
        // tel: { required},
        email: { required, email },
        libelle: { required },
        code_postal: { required },
        ville_id: { required },
      },
      civilite: { required },
      nom: { required },
      prenom: { required },
      situation_familiale: { required },
      statut: { required },
      nom_naissance: { required },
      nationalite: { required },
      code_postal_naissance: { required },
      commune_naissance: { required },
      pays_naissance: { required },
      enfantCharge: { required },
      secteur_activite: { required },

    }
  },
  created(){
    this.getPaysList()
  },
  methods: {
      handleClose(selectedDates, dateStr, instance) {
      instance.close(); // Ferme le calendrier lorsqu'on clique en dehors
    },
    handleDateChange(selectedDates) {
      if (selectedDates.length > 0) {
        this.benef.date_naissance = moment(selectedDates[0]).format('Y-MM-DD');
      }
    },
     validateEnfantCharge() {
      if (this.prospect.enfantCharge > 8) {
        this.prospect.enfantCharge = 8;
      }
    },
   removeBenef(index) {
      this.beneficieresData.splice(index, 1);
    },
    repeateAgain() {
      this.beneficieresData.push({
        id: null,
        civilite: null,
        nom: null,
        prenom: null,
        date_naissance: null,
        lieu_naissance: null,
        lien_parente: null,
        quota: null,
        state: false // Assuming state refers to the checkbox state
      });
    },
    enfnat_regime_obligatoire_mapped(regime){
        let findRegime = this.regimeSocial.find(obj => {
           return obj.value === regime;
        }); 
        return findRegime ? findRegime.name : ''
    },
    hasPermissionAssureur() {
      let result=false
      if(this.rl !== undefined) {
        let pr= _.find(this.rl.produitsFiltred, function(p) { return p.tarif.choix_client; });
        let prPermission = [19, 27, 23]
        if(pr && prPermission.find(prper => prper == pr.produit.id)){
          if(this.enfantsData.length > 0) {
            this.enfantsData.forEach((enf, index) => {
              if(pr.produit.id == 27) {
                this.disableEnfantNumSec = true
                this.enfantsData[index].hasnumsecu = true
              } else if(pr.produit.id == 19 || pr.produit.id == 23) {
                this.disableEnfantNumSec = false
                // if(this.enfantsData[index].regime_obligatoire_enf == 'REGIME_GENERAL' || this.prospect.regime_obligatoire_enf == this.enfantsData[index].regime_obligatoire_enf) {
                //   this.enfantsData[index].hasnumsecu = true
                // } else this.enfantsData[index].hasnumsecu = false
              } else {
                this.disableEnfantNumSec = false
              } 
            })
          }        
          result = true
        }
      }
      return result
    },
    initAyantDroit(val, index) {
      console.log(val, index)
      if(val) {
        this.enfantsData[index].ayantdroit = "sans"
      }
    },
    getPaysList() {
      this.$http.get(`tiers/getPays`)
      .then((r) => {
        let france = r.data.find(item => item.indicatif == "+33")
        r.data.sort(function(x,y){ return x == france ? -1 : y == france ? 1 : 0; })
        this.indicatif = r.data
        if (!this.prospect.moyen_contact.indicatif_tel) {
          this.prospect.moyen_contact.indicatif_tel = this.indicatif[0].indicatif
        }
      })
      .catch((e)=>{
        console.log(e);
      })
    },
    mapProspectLocal(tiers){
      this.prospect.id=tiers.id
      this.prospect.civilite=tiers.civilite
      this.prospect.nom=tiers.nom
      this.prospect.prenom=tiers.prenom
      this.prospect.date_naissance=tiers.date_naissance
      this.prospect.numeroOrganisme=tiers.numeroOrganisme
      this.prospect.type=tiers.type
      this.prospect.situation_familiale=tiers.situation_familiale
      this.prospect.statut_gda=tiers.statut_gda 
      this.prospect.ayantdroit=tiers.ayantdroit 
      this.prospect.nom_naissance=tiers.nom_naissance
      this.prospect.code_postal_naissance=tiers.code_postal_naissance
      this.prospect.commune_naissance=tiers.commune_naissance
      this.prospect.pays_naissance=tiers.pays_naissance
      this.prospect.regime_obligatoire=tiers.regime_obligatoire
      this.prospect.rang_laser=tiers.rang_laser
      this.prospect.enfantCharge= tiers.enfantCharge ? tiers.enfantCharge : null
      this.prospect.isPPE=tiers.isPPE
      this.prospect.statut=tiers.statut
      this.prospect.original_statut=tiers.statut
      this.prospect.nationalite=tiers.nationalite
      this.prospect.secteur_activite=tiers.secteur_activite
      this.prospect.moyen_contact.libelle=tiers.moyen_contact.libelle
      this.prospect.moyen_contact.lieu_dit_ou_bp=tiers.moyen_contact.lieu_dit_ou_bp
      this.prospect.moyen_contact.complement_adresse=tiers.moyen_contact.complement_adresse
      this.prospect.moyen_contact.tel=tiers.moyen_contact.tel
      this.prospect.moyen_contact.code_postal=tiers.moyen_contact.code_postal
      this.prospect.moyen_contact.ville_id=tiers.moyen_contact.ville_id
      this.prospect.moyen_contact.email=tiers.moyen_contact.email
      this.prospect.moyen_contact.indicatif_tel = tiers.moyen_contact.indicatif_tel
    },
    updateElementInRow(row, columnIndex, newValue) {
      var rowArray = row.split('');
      rowArray[columnIndex] = newValue;
      return rowArray.join('');
    },
 submit() {
  this.errors = [];
  this.$v.$reset();
  this.$v.prospect.$touch();
  this.$v.autreBeneficiers.$touch();
  if (this.objets.objet_deces.conjoint) {
    this.$v.ConjointLocal.$touch();
  }
  this.objets.objet_deces.autreBeneficieres=!this.autreBeneficiers ? 1 : 0;
  this.$v.beneficieresData.$touch();


  const checkedBeneficiaries = this.beneficieresData.filter(benef => benef.state);

  // Valider les quotas : assurer que chaque quota est d'au moins 1% et que le total est égal à 100%
  const totalQuota = checkedBeneficiaries.reduce((total, benef) => total + parseFloat(benef.quota || 0), 0);
  const invalidQuota = checkedBeneficiaries.some(benef => parseFloat(benef.quota || 0) < 1);

  if (
    this.$v.prospect.$error ||
    this.$v.ConjointLocal.$error ||
    (!this.autreBeneficiers && (this.$v.beneficieresData.$error ||
    checkedBeneficiaries.length === 0 || // Aucun bénéficiaire n'est coché
    invalidQuota || totalQuota !== 100))
    
  ) {
    if (!this.autreBeneficiers && this.beneficieresData.length === 0 ) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Veuillez renseigner les informations relatives aux bénéficiaires ajoutés.',
          icon: 'XIcon',
          variant: 'danger',
        },
      });
    } else if (checkedBeneficiaries.length === 0 && !this.autreBeneficiers) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Veuillez cocher au moins un bénéficiaire.',
          icon: 'XIcon',
          variant: 'danger',
        },
      });
    } else if (!this.autreBeneficiers && invalidQuota ) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Le quota doit être au moins 1%.',
          icon: 'XIcon',
          variant: 'danger',
        },
      });
    } else if (!this.autreBeneficiers && totalQuota !== 100) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Le total des quotas doit être égal à 100%.',
          icon: 'XIcon',
          variant: 'danger',
        },
      });
    }
    
    return 0;
  }
  else{
  // Envoi uniquement des bénéficiaires cochés
  const beneficieresToSend = this.beneficieresData.map(benef => ({
    id: benef.id,
    civilite: benef.civilite,
    nom: benef.nom,
    prenom: benef.prenom,
    date_naissance: benef.date_naissance,
    lieu_naissance: benef.lieu_naissance,
    lien_parente: benef.lien_parente,
    quota: benef.quota,
    state: benef.state
  }));
  this.$store.commit('setBeneficieresData', checkedBeneficiaries);
  this.$store.commit('setAutreBeneficiers', this.autreBeneficiers);
    this.updateDataSouscripteur(beneficieresToSend);
  }
},

    updateDataSouscripteur(beneficieresToSend=[]) {
    this.$emit('updateLoading',true)
     
      let data={
        prospect:this.prospect,
        enfant:this.formuleSelectedHasEnfnat ? this.enfantsData : [],
        conjoint:this.ConjointLocal,
        objets:this.objets,
        devis:this.rl.devis.id,
        ria:this.riaLocal,
        risque: { label: this.rl.risque.label },
        beneficiere : beneficieresToSend
      }
      this.$http.post(`portefeuilles/updateDataSouscripteurStrategy`,data).then((r) => {
        this.getBeneficiereDevisData()
          let dataMadelin={
              id:this.devis.id,
              loi_madelin:this.devis.loi_madelin
          }
           this.$http.post(`devis/updateDevisLoiMadelin`,dataMadelin).then((r) => {
              this.updateDevisSouscriptionAvancement()
              let prosp = JSON.parse(JSON.stringify(this.prospect))
              this.$store.commit('setProspect', {
                Tiers: prosp
              })
              // this.$store.commit("setObjetEntreprise", {
              //   Entreprise: this.EntrepriseLocal,
              // });
              this.$store.commit("setStepTarif", {
                  step: {
                    indexStep:2,
                    active:'paiement'
                  },
              });
              this.$emit('updateLoading',false)
          }).catch((err)=>{
              this.$emit('updateLoading',false)
              console.log(err)
          })
      }).catch((err)=>{
          if(is422(err)){
            this.$emit('updateLoading',false)
            this.errors = err.response.data.errors
          }
      })
    },
    backStep(){
      this.$store.commit("setStepTarif", {
          step: {
            indexStep:1,
            active:'choixclient'
          },
      });
    },
    async cpKeyup(){
      let cp=this.prospect.moyen_contact.code_postal
      if(cp.length==5){
          try{
              this.villesLocal = (await this.$http.get(`helpers/${cp}/villes`)).data
              if(this.prospect.moyen_contact.ville_id==null){
                  this.prospect.moyen_contact.ville_id=this.villesLocal[0].id
              }
          }catch(e){
              console.log(e)
          }
      }else{
              this.villesLocal=[]
              this.prospect.moyen_contact.ville_id=null
      }
    },
    async cpKeyupRia() {
      let cpLocal=this.riaLocal.codePostal;
      if(cpLocal && cpLocal.length==5){
          try {
            this.riaLocal.villesData=  (await this.$http.get(`helpers/${cpLocal}/villes`)).data;
          } catch (e) {
            this.riaLocal.ville_id=null
            this.riaLocal.villesData = []
          }
      }else{
            this.riaLocal.ville_id=null
            this.riaLocal.villesData = []
      }
    },
    async updateDevisSouscriptionAvancement(){
      let data={
          id:this.rl.devis.id,
          devis_etape_avancement:'paiement'
      }
      await this.$http.post(`devis/updateDevisSouscriptionState`,data).then((r) => {
          return  true
      }).catch((err)=>{
          console.log(err)
      })
    },
    initpaysProspect(pays){

    },
    async getBeneficiereDevisData(){
      await this.$http
        .get(`devis/getProspectDevisBeneficieres/${this.rl.devis.id}`)
        .then(r => {
           this.beneficieresData = r.data.map(benef => {
            return {
              ...benef,
              state: benef.state === 1 // Convertir en booléen : true si benef.state est 1, sinon false
            };
          });
          this.$store.commit('setBeneficieresData', this.beneficieresData);
          this.$store.commit('setAutreBeneficiers', this.autreBeneficiers);
          // this.mapBeneficiereStore(r.data)
        })
        .catch(e => {
          console.log(e)
        })
    },
     // Method to retrieve beneficiaries on component mount or when necessary
    hasErrors(val){
      if(this.errors){
        const found = Object.entries(this.errors).find(([key, value]) => key === val);
        return found ? found[1][0] : false
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}
#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}
#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}
#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}
#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}
.vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li
  .wizard-icon-circle
  .wizard-icon-container {
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked
{
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i
{
  color: #fff !important;
}
.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}
.vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li.active
  a
  .checked
  ~ .stepTitle {
  color: #4d25bc !important;
}
@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing
{
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body
{
  padding: 0 !important;
}
.resume > .card > .card-header
{
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight:bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.propositions .card button
{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}
.title-custom-wizard{
    color: #4d25bc ;
}
</style>


<style>

.indicatif-chooser-souscription-santesenior .vs__dropdown-toggle {
  border-radius: 0.357rem 0px 0px 0.357rem;
  padding-bottom: 4.5px;
}

.indicatif-chooser-souscription-santesenior .vs__dropdown-toggle:focus {
    border-radius: 0.357rem 0 0 0.357rem;
}
</style>
