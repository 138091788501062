<template>
   <div>
    <b-overlay :show="showLoading" no-wrap />
    <b-row>
      <b-col cols="12" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">
            étape 1 : confirmation du choix du client
        </h2>
        <h6>
            Sélectionnez la formule choisie par le client (commentaire obligatoire)
        </h6>
      </b-col>
    </b-row>

    <b-row class="pricing-card" v-for="(rl, index) in this.resumeLocal" :key="index + 'content'">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="12"
        class="mx-auto"
      >
        <b-row style="justify-content: center;">
          <b-col md="12" lg="12" xl="4" class="resume" v-for="(pr, val) in filtreProduit(rl.produitsFiltred)" :key="val + 'produits'">
            <b-card :header="pr.produit.produit_nom" footer-tag="footer">
              <div class="d-flex flex-column bd-highlight justify-content-end text-center">
                <div class="pt-1 bd-highlight">
                  <div style="font-size: 12px; height: 15px" v-if="pr.tarif.preconise" icon="AwardIcon">
                    <b-badge variant="warning">
                      <feather-icon icon="AwardIcon" class="mr-25" />
                      <span>recommandée</span>
                    </b-badge>
                  </div>
                  <div icon="AwardIcon" style="font-size: 12px; height: 15px" v-else />
                </div>
                <h3 class="pt-1 bd-highlight">{{ pr.tarif.formule_commercial }}</h3>
                <div class="bd-highlight">
                  <span class="d-block" style="font-size: 12px; height: 15px" v-if="ifHasSurComp(pr) ? pr.hasSurComp : false">Option Surcomplémentaire **</span>
                  <span class="d-block" style="font-size: 12px; height: 15px" v-else></span>
                </div>
                <div class="pt-2 pb-1 bd-highlight">
                  <b-badge :variant="getGtNvByNiveauVariant(getGtNvByNiveau(pr.garanties[0], pr.tarif.formule))" class="mb-1"> {{ getGtNvByNiveau(pr.garanties[0], pr.tarif.formule) }}  </b-badge>
                </div>
                <div class="bd-highlight">
                  <span class="d-block" style="font-size: 12px; height: 15px" v-if="!pr.tarif.tarif.libelle" />
                  <span class="d-block" style="font-size: 12px; height: 15px" v-else-if="pr.tarif.tarif.libelle == 'Enfant'">Adulte Enfant</span>
                  <span class="d-block" style="font-size: 12px; height: 15px" v-else>{{ pr.tarif.tarif.libelle }}</span>
                </div>
                <div class="bd-highlight annual-plan plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 1.5rem" v-if="formatText(rl.risque.label) === 'SANTETNS'">{{ pr.tarif.tarif.tarif }}</span>
                  <span class="pricing-basic-value font-weight-bolder text-primary" v-else>{{ Number(pr.tarif.tarif).toFixed(2) }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/mois</sub>
                </div>
                <div class="pb-2 bd-highlight">
                  <span class="d-block" style="font-size: 12px; height: 15px" v-if="pr.tarif.tarif.tarif_pmss && pr.tarif.tarif.tarif_pmss > 0">
                    Soit {{ pr.tarif.tarif.tarif_pmss}} % du PMSS <span v-show="hasPmssPrivilege">***</span>
                  </span>
                  <span class="d-block" style="font-size: 12px; height: 15px" v-else>{{ pr.tarif.tarif.libelle }}</span>
                </div>
                <div class="pb-2 bd-highlight">
                  <b-row class="mb-1" v-for="(eg, cptValue) in rl.exigences" :key="cptValue + 'exigence'">
                    <b-col md="4">
                      <span class="cursor-pointer" v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="explicationExigenceLibel(eg.exigence_produits_details, pr.produit.id)">{{ eg.label }}</span>
                    </b-col>
                    <b-col md="6">{{ titleClass(getPtGtNvExigCorrespondant(index, pr.produit.produit_nom, eg.id, pr.tarif.formule)) }}</b-col>
                    <b-col lg="1" v-if="comparerExig(getExigence(eg.exigenceNiveauID, eg.exigenceniveaux), getPtGtNvExig(index, pr.produit.produit_nom, eg.id, pr.tarif.formule))>0">
                      <feather-icon icon="PlusIcon" size="16" color="#26bf39"/>
                    </b-col>
                    <b-col  v-else-if="comparerExig(getExigence(eg.exigenceNiveauID, eg.exigenceniveaux), getPtGtNvExig(index, pr.produit.produit_nom, eg.id, pr.tarif.formule))<0" lg="1" >
                      <feather-icon icon="MinusIcon" size="16" color="#bf2626"/>
                    </b-col>
                    <b-col  v-else lg="1">
                      <feather-icon icon="CheckIcon" size="16" color="#26bf39"/>
                    </b-col>
                  </b-row>
                </div>
                <div class="bd-highlight">
                  <b-form-textarea
                      placeholder="exemple : Le rapport qualité/prix de cette formule est le plus adapté compte tenu du besoin que vous avez exprimé."
                      v-model="pr.tarif.commentaire_client"
                      rows="3"
                      maxlength="255"
                  />
                </div>
              </div>
              <template #footer>
                <b-button block class="mt-2" @click="choisirProduit(pr.tarif.tarification_id)" :variant="pr.tarif.choix_client ? 'primary' : 'outline-primary'"> Choix Client </b-button>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mb-1">
        <b-alert
          variant="warning"
          show
          class="mb-2 mx-2"
        >
          <div class="alert-body">
            <span>
              Commentaire : Il s’agit des raisons pour lesquelles cette formule a été choisie. 
            </span>
            <br>
            <span>
              La motivation doit être claire, compréhensible et ne pas renvoyer de façon elliptique aux Conditions Générales ou à une documentation non synthétique.
              La motivation doit consister à justifier la cohérence du produit avec les exigences de votre client.
            </span>
          </div>
        </b-alert>

      </b-col>
    </b-row>

    <b-row>
        <b-col cols="12" class="mb-2">
          <h6 class="mx-2">
              * Adhérents aux dispositifs de pratique tarifaire maitrisée
          </h6>
        </b-col>
        <b-col cols="12" class="mb-2" v-show="ifChooseSurComp()">
          <h6 class="mx-2">
              ** L’option surcomplémentaire permet de bénéficier de garanties renforcées chez les praticiens non adhérents à l’OPTAM
          </h6>
        </b-col>
        <b-col cols="12" v-show="hasPmssPrivilege">
          <h6 class="mx-2">
              *** Pour {{currentYear+1}}, le tarif est donné à titre indicatif sur la base d’une estimation du PMSS
          </h6>
        </b-col>
    </b-row>
    
    <b-row>
      <b-col lg="12">
        <b-button
          v-if="canNext"
          class="float-right"
          variant="primary"
          size="lg"
          @click="submitProduit(0)"
        >
          Suivant
        </b-button>
      </b-col>
    </b-row>

    <!-- <b-row class="float-right mb-4">
      <b-col lg="4" >
        <b-button
          v-if="canNext"
          variant="primary"
          size="lg"
          @click="submitProduit(0)"
        >
          Suivant
        </b-button>
      </b-col>
    </b-row> -->
   </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import moment from 'moment'
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  VBTooltip,
  VBPopover,
  BAlert,
  BBadge,
  
} from "bootstrap-vue";
import { required, email } from "@validations";
import { selectRisqueAndProduct,updateProduitTarif } from '../../../../shared/utils/TarifCalcul'
//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BAlert,
    BBadge
  },
  props:['showLoading'],
  computed: {
    ...mapGetters(["getItemsRisque"]),
    ...mapGetters(["getSelectedRisque"]),
    ...mapGetters(["getEtude"]),
    canNext :function(){
      return _.find(this.resumeLocal[0].produitsFiltred, function(p) { return p.tarif.choix_client; });
    },
    hasPmssPrivilege :function(){
      const result1 = this.currentYear + 1 === moment(this.resumeLocal[0].objets.objet_sante_tns.date_effet, "DD/MM/YYYY").year();
const result2 = this.filtreProduit(this.resumeLocal[0].produitsFiltred).some(pr => pr.produit.id !== 24);

return result1 && result2;
    },
  },
  watch: {
    // getItemsRisque: {
    //     handler: function (val, oldVal) {
    //       this.devis = this.getItemsRisque.ItemsRisque;
    //     },
    //     immediate: true,
    //     deep:true
    // },
    getSelectedRisque: function (val, old) {
      this.indexRisque = this.getSelectedRisque;
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  data() {
    return {
      resumeLocal: [],
      indexLocal:0,
      indexRisque:0,
      prospect:null,
      hasProduct:false,
      hasCommentedProduit:false,
      hasSelectedProduit:false,
      BooleanValue: [
        { value: true, text: "Oui" },
        { value: false, text: "Non" },
      ],
      BooleanValue1: [
        { value: 1, text: "Oui" },
        { value: 0, text: "Non" },
      ],
      currentYear:moment(new Date(), "DD/MM/YYYY").year()
    };
  },
  created() {
    this.getEtudeData(this.$route.params.id).then((res)=> {
      this.arrayProduit()
      this.hasProduct=false
      if(this.resumeLocal.length>0){
        this.resumeLocal.map((risque)=>{
            if(risque.produitsFiltred.length>0 && !this.hasProduct){
              risque.produitsFiltred.map((rpf)=>{
                if(rpf.tarif.presente)
                this.hasProduct=true
              })
            }
        })
        this.$emit('updateLoading',false)
      }

    }).catch(()=>{
      this.$emit('updateLoading',false)
    })
  },
  mounted() {

  },
  methods: {
    explicationExigenceLibel(exProduitsDetails, produitSelected) {
      let expDetail = exProduitsDetails.find((exp) => exp.produit_id == produitSelected)
      return expDetail != undefined ? expDetail.info : ''
    },
    ifChooseSurComp(){
      if(this.resumeLocal[0].produitsFiltred && this.resumeLocal[0].produitsFiltred.length>0){
        let choosedSurComp=_.find(this.resumeLocal[0].produitsFiltred, (value,key) => value.hasSurComp && value.tarif.presente)
        return choosedSurComp ? true : false
      }else{
        return false
      }
    },
    ifHasSurComp(produit){
      produit.hasSurComp=false
      if(produit.garanties && produit.garanties.length>0){
        produit.garanties.map((gn)=>{
          let result=_.find(gn.garantieniveaux, function(gnr) { return gnr.id == gn.garantieNiveauID[0] && gnr.garantie_niveau_libel=='Oui' });
          if(result){
            produit.hasSurComp=true
          }
        })
      }
      return produit
    },
    comparerExig(ex1,ex2){
      let i=this.getExByScoringNumber(ex1);
      let j=this.getExByScoringNumber(ex2);
      if(i<j) return 1;
      else if(i>j) return -1;
      else return 0;
    },
    getExByScoringNumber (ex1) {
      switch(ex1){
            case 'NON':
                return 0;
            case 'MIN':
                return 1;
            case 'MOYEN':
                return 2
            case 'FORT':
                return 3;
            case 'MAX':
                return 4;
            case 'MAXPLUS':
                return 5;
            default:
                return 1;
        }
    },
    titleClass(word){
     return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : '';
    },
    getPtGtNvExig(risque, produit, exigence, formule) {
      const produit_found = this.resumeLocal[risque].produitsFiltred.find(item => item.produit.produit_nom == produit)
      if(produit_found){
        let result = produit_found.produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle == formule);
        if (result && result.length > 0) {
          if (result[0].exigencesniveaux.length > 0) {
            let resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
            return resultFiltre.length > 0 ? resultFiltre[0].exigence_niveau_libel : ''
          }
        } else {
          return ''
        }
      }
    },
    getPtGtNvExigCorrespondant(risque,produit,exigence,formule){
      const produit_found = this.resumeLocal[risque].produitsFiltred.find(item => item.produit.produit_nom == produit)
      if(produit_found){
        let result = produit_found.produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle == formule);

        if(result && result.length>0){
           if(result[0].exigencesniveaux.length> 0){
              let resultFiltre=result[0].exigencesniveaux.filter(egn => egn.exigence_id == exigence)
              return resultFiltre.length>0 ?  resultFiltre[0].pivot ? resultFiltre[0].pivot.label_correspondant : '': ''
            }
         }else{
           return ''
         }
      }
    },
    getExigence(egn,egnArray){
      let tmpOption=_.find(egnArray, (value,key) => value.id==egn)
      return tmpOption ? tmpOption.exigence_niveau_libel : ''
    },
    filtreProduit(data){
      return data ?  data.filter(pr => pr.tarif.presente) : []
    },
    async getEtudeData(etude) {

      await this.$http
        .get(`portefeuilles/getEtude/${etude}`)
        .then(r => {
          this.updateTarifModule(r.data.data)
          this.prospect = this.Tiers
          this.$store.commit('setChangedRisque', {})
        })
        .catch(e => {
          console.log(e)
        })
    },
    updateTarifModule(data) {
      let tmpProspect = data.Tiers
      tmpProspect.date_naissance = moment(tmpProspect.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY')
      this.$store.state.tarifModule.etude = {
        etudeID: data.etudeID,
        etudeNom: data.etudeNom,
        ipid: data.ipid,
        cg: data.cg,
        tg: data.tg,
        Tiers: tmpProspect,
        checkedRisque: data.checkedRisque,
        itemsRisque: data.itemsRisque,
        Conjoint: data.Conjoint,
        Enfant: data.Enfant,
        Entreprise: data.Entreprise,
        changedRisque: false,
        step: data.itemsRisque.ItemsRisque[0].devis.devis_etape_avancement,
        etudeAvancement: null,
        conseilpath: null,
        selectedRisque: 0,
        activeStep: [
          { indexStep: 0, active: 'choix' },
          { indexStep: 1, active: 'souscripteur' },
          { indexStep: 2, active: 'benificaire' },
          { indexStep: 3, active: 'paiement' },
          { indexStep: 4, active: 'document' },
          { indexStep: 5, active: 'signature' },
        ]
      }
      this.resumeLocal= this.$store.state.tarifModule.etude.itemsRisque.ItemsRisque
    },
    async choisirProduit(tarification){
        await this.updateProduitSelected(tarification,0)
    },
    updateProduitSelected(tarification,index){
        this.resumeLocal[index].produitsFiltred.map((pr,val)=>{
            if(pr.tarif.tarification_id==tarification){
                pr.tarif.choix_client=true
            }
            else
                pr.tarif.choix_client=false
        })
    },
    arrayProduit(){
      let produitFiltre=[]
      console.log(this.resumeLocal)
      if(this.resumeLocal.length>0){
        this.resumeLocal.map((risque)=>{
            produitFiltre=[]
            risque.produitsFiltred=[]
            risque.produits.map((produit)=>{
            if(produit.tarif){
                produit.tarif.map((tf)=>{
                  const { tarif, ...newProduit } = produit;
                  let garantiesLocal=tf.garanties
                  const {garanties, ...tarifLocal} = tf;
                  produitFiltre.push({
                    produit:newProduit,
                    garanties: garantiesLocal,
                    tarif:tarifLocal
                  })
                })
              }
            })
            if(produitFiltre.length>0){
              let data={
                produits:produitFiltre,
                exigences:risque.exigences,
                garanties:risque.garanties,
                risque:risque.risque.label,
                objets:risque.objets
              }
              //let tmpProduits=selectRisqueAndProduct(data)
              risque.produitsFiltred=this.sortByTauxDec(data.produits);
            }
        })
      }
    },
    sortByTauxDec(data){
      function UpDowncompare( a, b ) {
        if(a.tarif.taux ==b.tarif.taux){
          if ( a.tarif.tauxtype < b.tarif.tauxtype ){
            return 1;
          }
          if ( a.tarif.tauxtype > b.tarif.tauxtype ){
            return -1;
          }
        }
        else{
          if ( a.tarif.taux < b.tarif.taux ){
            return 1;
          }
          if ( a.tarif.taux > b.tarif.taux ){
            return -1;
          }
        }
        return 0;
      }
      data.sort(UpDowncompare);
      return data
    },
    formatText(str) {
        var map = {
            '-' : ' ',
            '-' : '_',
            'a' : 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
            'e' : 'é|è|ê|ë|É|È|Ê|Ë',
            'i' : 'í|ì|î|ï|Í|Ì|Î|Ï',
            'o' : 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
            'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
            'c' : 'ç|Ç',
            'n' : 'ñ|Ñ'
        };
        for (var pattern in map) {
            str = str.replace(new RegExp(map[pattern], 'g'), pattern);
        };
        return str.split(' ').join('').toUpperCase()
    },
    submitProduit(index){
        let validProduit  =null
        let typeVente     =null
        let is_a_distance =null
        validProduit      =this.resumeLocal[index].produitsFiltred.find(pr => pr.tarif.choix_client)
        typeVente         =this.resumeLocal[index].devis.type_vente
        is_a_distance     =this.resumeLocal[index].devis.is_a_distance


        if(!is_a_distance || (typeVente!==null && is_a_distance)){
          if(validProduit){
            if(validProduit.tarif.commentaire_client!==''){
                this.$emit('updateLoading',true)
                this.hasCommentedProduit=false
                let produisChoisi=[]
                this.resumeLocal[index].produitsFiltred.map((rlp)=>{
                    produisChoisi.push({
                        choix_client:rlp.tarif.choix_client,
                        commentaire_client:rlp.tarif.commentaire_client,
                        tarification_id:rlp.tarif.tarification_id,
                        assureur_id:rlp.produit.assureur_id
                    })
                })
                let data={
                    produisChoisi:produisChoisi,
                    devis:this.resumeLocal[index].devis.id
                }
                this.$http.post(`portefeuilles/updateTarifChoixClient`,data).then((r) => {
                   /*  this.updateDevisLoiMadelin() */
                    this.updateObjetRembPrestationSante()
                    this.updateDevisSouscriptionAvancement(index)
                    this.updateDevisTypeVente(this.resumeLocal[0].devis.type_vente)
                    this.hasSelectedProduit=false
                    this.$emit('updateLoading',false)
                    this.$emit('hasEntrepriseStep', validProduit.produit.id != 24 ? true : false)
                    this.$emit('ProduitChoisi', validProduit.produit.id)
                    this.$store.commit("setStepTarif", {
                        step: {
                          indexStep:1,
                          active:'souscripteur'
                        },
                    });
                }).catch((err)=>{
                  this.$emit('updateLoading',false)
                  console.log(err)
                })
            }else{
                this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: 'Merci de saisir un commentaire',
                  icon: 'EditIcon',
                  variant: 'danger',
                  },
                })
                this.hasCommentedProduit=true
            }
          }else{
          this.$toast({
            component: ToastificationContent,
            props: {
            title: 'Merci de Choisir un produit',
            icon: 'EditIcon',
            variant: 'danger',
            },
          })
          this.hasSelectedProduit=true
        }

        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
            title: 'Merci de Choisir le type du vente',
            icon: 'EditIcon',
            variant: 'danger',
            },
          })

        }
        

    },
    async updateDevisSouscriptionAvancement(index){
        let data={
            id:this.resumeLocal[index].devis.id,
            devis_etape_avancement:'souscripteur'
        }
        await this.$http.post(`devis/updateDevisSouscriptionState`,data).then((r) => {
            return  true
        }).catch((err)=>{
            console.log(err)
        })
    },
    async updateDevisTypeVente(value){
        let data={
          id:this.resumeLocal[0].devis.id,
          type_vente:value,
          is_a_distance:this.resumeLocal[0].devis.is_a_distance
        }
        await this.$http.post(`devis/updateDevisTypeVente`,data).then((r) => {
            return  true
        }).catch((err)=>{
            console.log(err)
        })
    },
    /* async updateDevisLoiMadelin(){
          let dataMadelin={
              id:this.resumeLocal[0].devis.id,
              loi_madelin:this.resumeLocal[0].devis.loi_madelin
          }
          this.$http.post(`devis/updateDevisLoiMadelin`,dataMadelin).then((r) => {
             console.log('loi madelin updated')
          }).catch((err)=>{
              console.log(err)
          })
    }, */
    async updateObjetRembPrestationSante(){
          let objetSanteTNs={
              id:this.resumeLocal[0].objets.objet_sante_tns.id,
              remb_prestation_sante:this.resumeLocal[0].objets.objet_sante_tns.remb_prestation_sante,
              objet_type: 'tns'
          }
          this.$http.post(`portefeuilles/updateObjetRembPrestationSante`,objetSanteTNs).then((r) => {
             console.log('rembouresement prestation sante updated')
          }).catch((err)=>{
              console.log(err)
          })
    },
    getGtNvByNiveau(garantie,niveau){
      let tmpGNPOption = null
      garantie.produit_garantie.forEach(element => {
        element.garantiesniveaux.forEach(element1 => {
          if (element1.pivot.libelle == niveau) {
            tmpGNPOption = element1.garantie_niveau_libel
            return tmpGNPOption
          }
        });
      });
      return tmpGNPOption
    },
    getGtNvByNiveauVariant(niveau) {
      switch (niveau) {
        case 'Economique':
          return 'light-success'
        case 'Complète':
          return 'light-warning'
        case 'Haut de gamme':
          return 'light-danger'
        default:
          return 'light-success'
      }
    }, 
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing
{
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body
{
  padding: 0 !important;
}
.resume > .card > .card-header
{
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight:bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}


.propositions .card button
{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

.box-icon-custom{
  color: #4d25bc;
  margin-top: -5px;
}
</style>

